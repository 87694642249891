<template>

  <section class="mt-4 pb-5">

    <div class="container" >
      <div class="inner_page_two_sides">
        <div class="right_side">
          <div>
            <section class="status">
              <div class="container status_style">
                <div class="status_box_wrapper">
                  <div class="icon">
                  </div>
                  <div class="title mt-3">
                    {{ $t('main.maintaining') }}
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

export default {
  name: 'Maintaining',


}
</script>
